import Footer from './components/include/Footer';
import Navbar from './components/include/Navbar';
import Topbar from './components/include/Topbar';
import '../src/assets/styles/Style.scss'
import Home from './components/Home/Home';
import BookingDetails from './components/Pages/BookingDetails/BookingDetails';
import {BrowserRouter, Route} from "react-router-dom";
import React, {useEffect} from "react";
import axios from "axios";
import Auth from "./auth";
import MyBooking from "./components/ClientArea/MyBooking";
import './AxiosInterceptor'
import {BRAND} from "./GlobalFunction";
import fav from './assets/images/favicon.ico'
import fav_am from './assets/images/logo_am.jpeg'
import {Helmet} from "react-helmet";

function App() {
    useEffect(()=>{
        const token = localStorage.user_token
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
    }, [])


    useEffect(() => {
        // Replace 'dynamicFaviconUrl' with the URL of your dynamic favicon
        const dynamicFaviconUrl = BRAND === 'DF'? fav : fav_am;

        // Create a new link element
        const link = document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'icon';
        link.href = dynamicFaviconUrl;

        // Remove existing favicon (if any)
        const existingFavicon = document.querySelector('link[rel="icon"]');
        if (existingFavicon) {
            document.head.removeChild(existingFavicon);
        }

        // Append the new link element to the head
        document.head.appendChild(link);
    }, []); // Empty dependency array ensures the effect runs only once after the component mounts
    const navbarStyle = {
        position: 'sticky',
        top: 0,
        zIndex: 1000,
      };


    return (
        <div>
            <Helmet>
                <title>{BRAND === 'DF' ? 'Dayfuna' : 'Amar Atithi'}</title>
            </Helmet>
            <BrowserRouter>
                <div style={navbarStyle}>
                <Topbar/>
                <Navbar/>
                </div>
                <Route exact path={'/'} component={Home} />
                <Route exact path={'/listing/:slug'} component={BookingDetails}/>
                <Auth exact path={'/my-demo'} component={MyBooking}/>
                <Auth exact path={'/my-booking'} component={MyBooking}/>
            </BrowserRouter>
            <Footer/>
        </div>
    );
}

export default App;
