import React from 'react';
import {BRAND} from "../../GlobalFunction";

const Topbar = () => {


    return (
      <div className="top-bar-container">
        <div className="container">
          <div className="row">
            <div className="col social-left">
              <a href='mailto:traveltour@gmail.com'><i className="fa-solid fa-envelope"/> info@{BRAND == 'DF'? 'dayfuna': 'amaratithi'}.com</a>
              <a href='tel:00000000000'><i className="fa-solid fa-phone"></i> 00000000000</a>
            </div>
            <div className="col social-right">
              <a href='#'>
                <i className="fa-brands fa-facebook-square"></i>
              </a>
              <a href='#'>
                <i className="fa-brands fa-twitter-square"></i>
              </a>
              <a href='#'>
                <i className="fa-brands fa-instagram-square"></i>
              </a>
              <a href='#'>
                <i className="fa-brands fa-whatsapp-square"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Topbar;
