import React, { useEffect, useState, useCallback } from "react";
import Slider from "react-slick";
import Constants from "../../../Constants";
import axios from "axios";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import debounce from "lodash.debounce";

const HomeItemCard = () => {
  const [categories, setCategories] = useState([]);
  const [listings, setListing] = useState([]);
  const [category, setCategory] = useState(6);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getCategories = async () => {
    try {
      const res = await axios.get(Constants.BASE_URL + "/get-categories");
      console.log(res.data);
      setCategories(res.data.data);
    } catch (err) {
      setError("Failed to load categories");
    }
  };

  const getListing = async (categoryId) => {
    setLoading(true);
    try {
      const res = await axios.get(Constants.BASE_URL + "/get-listing?category_id=6", {
        params: { category_id: categoryId },
      });
      console.log("data---------------",res.data);
      setListing(res.data.data);
    } catch (err) {
      setError("Failed to load listings");
    } finally {
      setLoading(false);
    }
  };

  const debouncedGetListing = useCallback(
    debounce((categoryId) => {
      getListing(categoryId);
    }, 300),
    []
  );

  const handleCategory = (id) => {
    setCategory(id);
    debouncedGetListing(id);
  };

  useEffect(() => {
    getCategories();
    getListing(null);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 9,
    slidesToScroll: 1,
  };

  return (
    <div className="container section-gap">
      <div className="row">
        <div className="col-md-12 my-5">
          <h1 className="text-center text-uppercase text-theme">
            Find your best match
          </h1>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-md-12">
          <div className="category-area">
            {categories.length > 0 ? (
              <Slider {...settings}>
                {categories.map((cat, index) => (
                  <div
                    key={index}
                    onClick={() => handleCategory(cat.id)}
                    className="category-item"
                  >
                    <img className="p-1" src={cat.icon} alt={cat.name} />
                    <p className="p-1">{cat.name}</p>
                    <div
                      style={{
                        border:
                          category === cat.id ? "1px solid #008B8B" : "none",
                      }}
                    ></div>
                  </div>
                ))}
              </Slider>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <p>Loading categories...</p>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        {loading ? (
          <div className="col-md-12 text-center mt-5">
            <p>Loading...</p>
          </div>
        ) : listings.length > 0 ? (
          listings.map((listing, index) => (
            <div className="col-md-3 mt-4" key={index}>
              <Link to={`/listing/${listing.slug}`}>
                <div className="home-card-item h-100">
                  <div className="image-container">
                    <button className="btn-favorite">
                      <i className="fa-solid fa-heart"></i>
                    </button>
                    <span>New</span>
                    <img
                      src={listing.cover_photo}
                      className="img-thumbnail"
                      alt="photo"
                    />
                  </div>
                  <div className="content">
                    <h6>{listing.title}</h6>
                    <p>{listing.description}</p>
                    <p>Aug 1 – 7</p>
                    <p>
                      <strong>{listing.discount_price} USD</strong> Night
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          ))
        ) : error ? (
          <p>{error}</p>
        ) : (
          <div className="col-md-12 text-center mt-5">
            <p>Not Found</p>
          </div>
        )}
      </div>
      <div className="col-md-12 my-4 text-center">
        <button className="view-more-button mt-4">View More</button>
      </div>
    </div>
  );
};

export default HomeItemCard;

// import React, { useEffect, useState } from "react";
// import Slider from "react-slick";
// import Constants from "../../../Constants";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// const HomeItemCard = () => {
//   const [categories, setCategories] = useState([]);
//   const [listings, setListing] = useState([]);
//   const [category, setCategory] = useState(null);

//   const getCategories = () => {
//     axios.get(Constants.BASE_URL + "/get-categories").then((res) => {
//       setCategories(res.data.data);
//     });
//   };
//   const getListing = () => {
//     axios
//       .get(Constants.BASE_URL + "/get-listing?category_id=" + category)
//       .then((res) => {
//         setListing(res.data.data);
//       });
//   };

//   const handleCategory = (id) => {
//     setCategory(id);
//     console.log(id);
//   };
//   useEffect(() => {
//     getListing();
//   }, [category]);

//   useEffect(() => {
//     getCategories();
//     getListing();
//   }, []);

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 9,
//     slidesToScroll: 1,
//   };

//   return (
//     <div className={"container section-gap"}>
//       <div className="row">
//         <div className="col-md-12 my-5">
//           <h1 className={"text-center text-uppercase text-theme"}>
//             Find your best match
//           </h1>
//         </div>
//       </div>
//       <div className="row align-items-center">
//         <div className="col-md-12">
//           <div className="category-area">
//             {Object.keys(categories).length > 0 ? (
//               <Slider {...settings}>
//                 {categories.map((category, index) => (
//                   <div
//                     key={index}
//                     onClick={() => handleCategory(category.id)}
//                     className="category-item"
//                   >
//                     <img src={category.icon} alt={category.name} />
//                     <p>{category.name}</p>
//                   </div>
//                 ))}
//               </Slider>
//             ) : null}
//           </div>
//         </div>
//       </div>
//       <div className="row">
//         {listings.map((listing, index) => (
//           <div className="col-md-3 mt-4" key={index}>
//             <Link to={"/listing/" + listing.slug}>
//               <div className="home-card-item h-100">
//                 <div className="image-container">
//                   <button className="btn-favorite">
//                     <i className="fa-solid fa-heart"></i>
//                   </button>
//                   <span>New</span>
//                   <img
//                     src={listing.cover_photo}
//                     className={"img-thumbnail"}
//                     alt={"photo"}
//                   />
//                 </div>
//                 <div className="content">
//                   <h6>{listing.title}</h6>
//                   <p>{listing.description}</p>
//                   <p>Aug 1 – 7</p>
//                   <p>
//                     <strong>{listing.discount_price} USD</strong> Night
//                   </p>
//                 </div>
//               </div>
//             </Link>
//           </div>
//         ))}
//       </div>
//       <div className="col-md-12 my-4 text-center">
//         <button className={"view-more-button mt-4"}>View More</button>
//       </div>
//     </div>
//   );
// };

// export default HomeItemCard;
