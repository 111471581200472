import axios from "axios";
import GlobalFunction, {BRAND} from "./GlobalFunction";

axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.headers['brand'] = BRAND;

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger

    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (error.response.status == 401) {
        if (window.location.origin != 'http://localhost:3000') {
            GlobalFunction.logOut()
        }
    } else if (error.response.status == 500) {
        // window.location.href = window.location.origin + '/error-500'
    }
    return Promise.reject(error);
});